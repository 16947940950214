@import url("https://static.giraffe360.com/immersive-content/static/fonts/roboto.css");
@import url("https://static.giraffe360.com/immersive-content/static/fonts/open-sans.css");
@import url("https://static.giraffe360.com/immersive-content/static/fonts/gilroy.css");

@tailwind components;
@tailwind utilities;

#vt-skin {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  pointer-events: none;
  overflow: hidden;
  user-select: none;
}

.vt-player-lib {
  overflow: hidden;

  .vt-theme-dark {
    --opacity-low: 0.25;
    --opacity-medium: 0.6;
    --opacity-normal: 0.6;
    --opacity-text-bg: 0.6;

    --color-text-primary: 255, 255, 255; /* #FFFFFF */
    --color-text-secondary: 10, 13, 18; /* #0A0D12 */
    --color-text-contrast: 255, 255, 255; /* #FFFFFF */

    --color-bg-primary: 26, 34, 46; /* #1A222E */
    --color-bg-secondary: 255, 255, 255; /* #FFFFFF */
    --color-bg-tertiary: 26, 34, 46; /* #1A222E */

    --scrollbar-color: rgba(215, 218, 221, 1); /* #D7DADD */
  }

  .vt-theme-light {
    --opacity-low: 0.3;
    --opacity-medium: 0.5;
    --opacity-normal: 0.8;
    --opacity-text-bg: 0.7;

    --color-text-primary: 255, 255, 255; /* #FFFFFF */
    --color-text-secondary: 10, 13, 18; /* #0A0D12 */
    --color-text-contrast: 10, 13, 18; /* #0A0D12 */

    --color-bg-primary: 255, 255, 255; /* #FFFFFF */
    --color-bg-secondary: 255, 255, 255; /* #FFFFFF */
    --color-bg-tertiary: 163, 166, 169; /* #A3A6A9 */

    --scrollbar-color: rgba(215, 218, 221, 1); /* #D7DADD */
  }

  @layer components {
    .hot-spot-content a {
      color: #485363;
      text-decoration: underline;
    }

    .btn-theme-primary {
      @apply pointer-events-auto flex min-w-0 items-center justify-center rounded-lg bg-theme-primary
      bg-opacity-theme-low fill-theme-primary stroke-theme-primary p-2 text-center font-primary text-lg
      font-bold text-theme-primary text-shadow-dark hover:bg-opacity-theme-medium;
    }

    .btn-theme-secondary {
      @apply pointer-events-auto flex min-w-0 items-center justify-center rounded-[0.438rem]
      bg-theme-primary bg-opacity-0 fill-theme-primary stroke-theme-primary p-2 text-center font-primary
      text-lg font-bold text-theme-primary text-shadow-dark hover:bg-theme-secondary
      hover:bg-opacity-100 hover:fill-theme-secondary hover:stroke-theme-secondary
      hover:text-theme-secondary hover:text-shadow-none;
    }

    .btn-theme-active {
      @apply pointer-events-auto flex min-w-0 items-center justify-center rounded-[0.438rem] bg-theme-secondary
      bg-opacity-100 fill-theme-secondary stroke-theme-secondary p-2 text-center font-primary
      text-lg font-bold text-theme-secondary hover:bg-opacity-100;
    }

    .scrollbars-theme {
      @apply pointer-events-auto relative overflow-auto rounded-lg bg-theme-tertiary bg-opacity-theme-normal p-0.5;
    }

    .bottom-gradient-light {
      background: linear-gradient(
        0deg,
        rgba(26, 34, 46, 0.35) 0%,
        rgba(26, 34, 46, 0.35) 6.67%,
        rgba(26, 34, 46, 0.34) 13.33%,
        rgba(26, 34, 46, 0.32) 20%,
        rgba(26, 34, 46, 0.3) 26.67%,
        rgba(26, 34, 46, 0.27) 33.33%,
        rgba(26, 34, 46, 0.23) 40%,
        rgba(26, 34, 46, 0.2) 46.67%,
        rgba(26, 34, 46, 0.15) 53.33%,
        rgba(26, 34, 46, 0.12) 60%,
        rgba(26, 34, 46, 0.08) 66.67%,
        rgba(26, 34, 46, 0.05) 73.33%,
        rgba(26, 34, 46, 0.03) 80%,
        rgba(26, 34, 46, 0.01) 86.67%,
        rgba(26, 34, 46, 0) 93.33%,
        rgba(26, 34, 46, 0) 100%
      );
    }

    .bottom-gradient-dark {
      background: linear-gradient(
        0deg,
        rgba(26, 34, 46, 0.3) 0%,
        rgba(26, 34, 46, 0.3) 6.67%,
        rgba(26, 34, 46, 0.29) 13.33%,
        rgba(26, 34, 46, 0.28) 20%,
        rgba(26, 34, 46, 0.26) 26.67%,
        rgba(26, 34, 46, 0.23) 33.33%,
        rgba(26, 34, 46, 0.2) 40%,
        rgba(26, 34, 46, 0.17) 46.67%,
        rgba(26, 34, 46, 0.13) 53.33%,
        rgba(26, 34, 46, 0.1) 60%,
        rgba(26, 34, 46, 0.07) 66.67%,
        rgba(26, 34, 46, 0.04) 73.33%,
        rgba(26, 34, 46, 0.02) 80%,
        rgba(26, 34, 46, 0.01) 86.67%,
        rgba(26, 34, 46, 0) 93.33%,
        rgba(26, 34, 46, 0) 100%
      );
    }
  }

  @layer utilities {
    .grid-auto-fill {
      grid-template-columns: repeat(auto-fit, minmax(auto, 114px));
    }
  }
}
