@import url("https://static.giraffe360.com/immersive-content/static/fonts/gilroy.css");

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  @apply font-primary text-giraffe-dark;
  height: 100%;
  width: 100%;
}

:root {
  --toastify-toast-width: auto !important;
}

h1,
h2,
h3,
h4,
h5 {
  @apply font-bold font-primary;
}

p {
  @apply text-sm font-normal font-secondary tracking-[0.01em];
}
p.text.base {
  @apply text-sm leading-[30px];
}
p.text.sm {
  @apply text-sm leading-[25px];
}
p.xs {
  @apply text-xs;
}
p.label {
  @apply font-bold font-secondary;
}
p.label.md {
  @apply font-normal;
}
p.label.xs {
  @apply text-xs;
}
p.label.xs.md {
  @apply font-normal;
}

h1 {
  @apply text-3xl;
}

h2 {
  @apply text-2xl;
}

h3 {
  @apply text-xl;
}

h4 {
  @apply text-lg;
}

h5 {
  @apply text-base;
}

p.text {
  @apply leading-[25px];
}
p.label {
  @apply font-bold font-primary;
}
p.label.md {
  @apply font-normal;
}
p.label.xs {
  @apply text-xs;
}

@layer utilities {
  .delay-1 {
    animation-delay: 0.1s;
  }
  .delay-2 {
    animation-delay: 0.2s;
  }
  .delay-3 {
    animation-delay: 0.3s;
  }

  .-z-1 {
    z-index: -1;
  }

  .origin-0 {
    transform-origin: 0%;
  }

  input[type="text"],
  input[type="password"],
  input[type="email"] {
    border: none;
  }

  input:focus ~ label,
  input:not(:placeholder-shown) ~ label,
  textarea:focus ~ label,
  textarea:not(:placeholder-shown) ~ label,
  select:focus ~ label,
  select:not([value=""]):valid ~ label {
    /* @apply transform; scale-75; -translate-y-6; */
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    transform: translateX(var(--tw-translate-x))
      translateY(var(--tw-translate-y)) rotate(var(--tw-rotate))
      skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
      scaleY(var(--tw-scale-y));
    --tw-scale-x: 0.75;
    --tw-scale-y: 0.75;
    --tw-translate-y: -0.7rem;
  }

  input:focus ~ label,
  select:focus ~ label {
    /* @apply text-black; left-0; */
    /* --tw-text-opacity: 0.5;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
    left: 0px; */
  }
}

.Toastify__toast-container--top-center {
  top: 0.09rem !important;
}

.Toastify__progress-bar {
  opacity: 0 !important;
}

.Toastify__toast-body {
  padding: 0 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 100% !important;
}

.radial-gradient-inner {
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.9) 35%,
    rgba(255, 255, 255, 0) 100%
  );
}

.radial-gradient-outer {
  background: radial-gradient(
    circle,
    rgba(83, 93, 100, 0.58) 0%,
    rgba(83, 93, 100, 0.58) 100%
  );
}

.vt-theme-dark {
  --opacity-low: 0.15;
  --opacity-medium: 0.5;
  --opacity-normal: 0.5;
  --opacity-high: 1;

  --color-text-primary: 255, 255, 255;
  --color-text-secondary: 10, 13, 18;
  --color-text-contrast: 255, 255, 255;

  --color-bg-primary: 26, 34, 46;
  --color-bg-secondary: 255, 255, 255;
  --color-bg-tertiary: 26, 34, 46;

  --scrollbar-color: rgba(215, 218, 221, 1);
}

.vt-theme-light {
  --opacity-low: 0.2;
  --opacity-medium: 0.4;
  --opacity-normal: 0.7;
  --opacity-high: 1;

  --color-text-primary: 255, 255, 255;
  --color-text-secondary: 10, 13, 18;
  --color-text-contrast: 10, 13, 18;

  --color-bg-primary: 255, 255, 255;
  --color-bg-secondary: 255, 255, 255;
  --color-bg-tertiary: 188, 191, 193;

  --scrollbar-color: rgba(215, 218, 221, 1);
}
