.os-theme-custom {
  --os-size: 2px;
  --os-padding-perpendicular: 0px;
  --os-padding-axis: 6px;
  --os-track-border-radius: 4px;
  --os-handle-interactive-area-offset: 4px;
  --os-handle-border-radius: 4px;
  --os-handle-bg: var(--scrollbar-color);
  --os-handle-bg-hover: var(--scrollbar-color);
  --os-handle-bg-active: var(--scrollbar-color);
}

.os-theme-hotspot {
  --os-size: 4px;
  --os-padding-perpendicular: 0px;
  --os-padding-axis: 8px;
  --os-track-border-radius: 4px;
  --os-handle-interactive-area-offset: 4px;
  --os-handle-border-radius: 4px;
  --os-handle-bg: var(--scrollbar-color);
  --os-handle-bg-hover: var(--scrollbar-color);
  --os-handle-bg-active: var(--scrollbar-color);
}
